import type { Media } from "@shopware-pwa/types";

export const getMediaSrcSet = (media: Media | null) => {
  if (!media?.thumbnails?.length) return "";
  return media.thumbnails
    .map((thumbnail) => {
      return `${encodeURI(thumbnail.url)} ${thumbnail.width}w`;
    })
    .join(", ");
};
